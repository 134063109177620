<template>
  <div class="dealer-select">
    <ui-dropdown
      class="dealer-select__dropdown"
      :class="{
        'dealer-select__dropdown_error': isError,
        'dealer-select__dropdown_disabled': isDisabled
      }"
      :is-disabled="isDisabled"
      select
      @open="loadUsers"
    >
      <div class="dealer-select__current">
        <template v-if="selectedUser">
          <img
            class="dealer-select__current-ava"
            v-bind="selecterUserAvatar"
          >

          <p class="dealer-select__current-name">
            {{ selectedUser.fullName }}
          </p>
        </template>

        <p
          v-else
          class="dealer-select__current-name"
        >
          {{ $t('SELECT_PH') }}
        </p>
      </div>

      <div slot="content">
        <ui-dropdown-item class="dealer-select__item">
          <ui-text
            class="dealer-select__item-search"
            v-model="filter"
            look="secondary"
            fill="frame"
            :placeholder="$t('SEARCH_PH')"
            @input="loadUsersDebounced"
          />
        </ui-dropdown-item>

        <div
          v-if="pinnedOptions.length"
          class="dealer-select__pinned"
        >
          <ui-dropdown-item
            v-for="item in pinnedOptions"
            :key="item.id"
            class="dealer-select__item dealer-select__item_pinned"
            :class="{
              'dealer-select__item_active': value && item.id === value
            }"
            auto-close
            interactive
            @click="onSelect(item)"
          >
            <div class="dealer-select__item-main">
              <p class="dealer-select__item-name">
                {{ item.fullName }}
              </p>
            </div>
          </ui-dropdown-item>
        </div>

        <template v-if="mappedUsers.length && !isLoading">
          <ui-dropdown-item
            v-for="item in mappedUsers"
            :key="item.id"
            class="dealer-select__item"
            :class="{ 'dealer-select__item_active': item.id === value }"
            auto-close
            interactive
            @click="onSelect(item)"
          >
            <img
              class="dealer-select__item-ava"
              v-bind="item.avatarSrc"
            >

            <div class="dealer-select__item-main">
              <p class="dealer-select__item-name">
                {{ item.fullName }}
              </p>

              <p class="dealer-select__item-email">
                {{ item.email }}
              </p>
            </div>
          </ui-dropdown-item>
        </template>

        <ui-dropdown-item
          v-else
          look="secondary"
          class="dealer-select__msg"
        >
          <template v-if="isLoading">
            {{ $t('LOADING_MSG') }}
          </template>

          <template v-else>
            {{ $t('NOT_FOUND_MSG') }}
          </template>
        </ui-dropdown-item>
      </div>
    </ui-dropdown>

    <p class="dealer-select__error-msg">
      <slot
        v-if="isError"
        name="error"
      />
    </p>
  </div>
</template>

<script>
import {
  UiDropdown,
  UiDropdownItem,
  UiText,
  breakpoints
} from '@shelf.network/ui-kit'

import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { sdk } from 'Services/shelfNetworkSdk'

import { generateImageProps } from 'Utils/generateImageProps'
import { compareAlphabetically } from 'Utils/arrayHelpers'

import { Identity } from 'Models/Identity'
import { UserEntry } from 'Models/UserEntry'
import avaPh32Path from 'Assets/icons/ava-ph-32.svg'

import debounce from 'lodash/debounce'

const loneSdkCall = new LoneSdkCall()

export default {
  name: 'dealer-select',
  components: {
    UiDropdown,
    UiDropdownItem,
    UiText
  },

  props: {
    pinnedOptions: { type: Array, default: () => [] },
    value: { type: String, required: true },
    isError: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },

  data () {
    return {
      isLoading: false,
      filter: '',
      users: [],
      selectedUser: null,
    }
  },

  computed: {
    ...mapGetters({
      platformId: `entities/user/${userGetters.PLATFORM_ID}`
    }),

    selecterUserAvatar () {
      return this.selectedUser.avatarLink
        ? generateImageProps({
          alt: '',
          src: this.selectedUser.avatarLink,
          srcSetOptions: [
            { width: 32, height: 32 },
            { width: 48, height: 48 },
          ],
          sizes: [
            `(max-width: ${breakpoints.SM}px) 3vw`,
            '2vw',
          ].join(', '),
          progressive: false,
        })
        : { src: avaPh32Path }
    },

    mappedUsers () {
      return this.users.map(item => {
        return {
          ...item,
          avatarSrc: item.avatarLink
            ? generateImageProps({
              alt: '',
              src: item.avatarLink,
              srcSetOptions: [
                { width: 32, height: 32 },
                { width: 48, height: 48 },
              ],
              sizes: [
                `(max-width: ${breakpoints.SM}px) 3vw`,
                '2vw',
              ].join(', '),
              progressive: false,
            })
            : { src: avaPh32Path }
        }
      })
        .sort((a, b) => compareAlphabetically(a.fullName, b.fullName))
    }
  },

  watch: {
    value: {
      immediate: true,
      async handler (val) {
        if (!val) {
          this.selectedUser = null
          return
        }

        const { data: user } = await loneSdkCall.takeLatest(
          sdk.horizon.account.get(val)
        )
        this.selectedUser = new UserEntry(user)
      }
    }
  },

  methods: {
    onSelect (user) {
      this.filter = ''
      this.$emit('input', user.id)
      this.$emit('update-user', user)
    },

    loadUsersDebounced: debounce(async function () {
      await this.loadUsers()
    }, 500),

    async loadUsers () {
      this.isLoading = true

      try {
        const { data: users } = await loneSdkCall.takeLatest(
          sdk.copartBackOffice.getIdentities({
            query: {
              filter: { search: this.filter || '' },
              page: { limit: 20 },
              include: ['basics']
            }
          })
        )
        this.users = users.map(item => new Identity(item))
      } catch (e) {
        this.users = []
        console.error(e)
      }

      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dealer-select {
  &__dropdown {
    display: block;

    &_error {
      /deep/ .ui-dropdown__trigger {
        &_select-like[fill="frame"][look="default"] {
          border-color: $color-flag-is-error;
        }
      }
    }

    &_disabled {
      /deep/ .ui-dropdown__trigger {
        &_select-like[fill="frame"][look="default"] {
          border-color: $color-flag-is-disabled;
          background-color: $color-light-grey;
          color: $color-ui-secondary;

          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }

  &__pinned {
    border-bottom: 1px solid $color-grey;

    /deep/ .ui-dropdown-item {
      &:first-child,
      &:last-child {
        margin: 0;
      }
    }
  }

  &__current {
    display: flex;
    align-items: center;
    min-width: 0;

    &-ava {
      width: 1.75em;
      height: 1.75em;
      margin-right: 0.5em;
      border-radius: 50%;
    }

    &-name {
      @include ellipsis();
    }
  }

  &__item-search {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    min-width: 0;

    &_active {
      background-color: $color-ui-grey-blue2;
    }

    &-ava {
      width: 1.75em;
      height: 1.75em;
      margin-right: 0.5em;
      border-radius: 50%;
    }

    &-name,
    &-email {
      @include ellipsis();
    }

    &-email {
      font-size: 0.9em;
      color: $color-dark-grey;
    }
  }

  &__msg {
    color: $color-dark-grey;
  }

  &__error-msg {
    margin-top: 0.6em;
    color: $color-flag-is-error;
  }
}
</style>

<i18n>
{
  "en": {
    "SELECT_PH": "Choose dealer",
    "SEARCH_PH": "Search",
    "NOT_FOUND_MSG": "Users not found",
    "LOADING_MSG": "Loading…"
  },
  "ka": {
    "SELECT_PH": "აირჩიე დილერი",
    "SEARCH_PH": "ძიება",
    "NOT_FOUND_MSG": "არ მოიძებნა",
    "LOADING_MSG": "იტვირთება…"
  },
  "ru": {
    "SELECT_PH": "Выберите дилера",
    "SEARCH_PH": "Поиск",
    "NOT_FOUND_MSG": "Пользователи не найдены",
    "LOADING_MSG": "Загрузка…"
  },
  "uk": {
    "SELECT_PH": "Виберіть дилера",
    "SEARCH_PH": "Пошук",
    "NOT_FOUND_MSG": "Користувачів не знайдено",
    "LOADING_MSG": "Завантаження…"
  }
}
</i18n>
